import React, { useState } from 'react';
import './Navbar.css';
import { MenuItems } from './MenuItems';

function Navbar({ isAuthenticated, onLogout }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="NavbarItems">
      <h1 className="navbar-logo">Desainerimnco</h1>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={menuOpen ? 'fa fa-times' : 'fa fa-bars'}></i>
      </div>
      <ul className={menuOpen ? 'nav-menu active' : 'nav-menu'}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <a className={item.cName} href={item.url}>
              <i className={item.icon}></i> {item.title}
            </a>
          </li>
        ))}

        {/* Conditionally render Logout or Sign Up */}
        {!isAuthenticated ? (
          <li>
            <a className="nav-links-mobile" href="/login">
              <i className="fa fa-sign-in"></i> login
            </a>
          </li>
        ) : (
          <li>
            <button className="nav-links-mobile" onClick={onLogout}>
              <i className="fa fa-sign-out"></i> Logout
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;


