import { FaHandPointer } from 'react-icons/fa'; 
import "./Product.css";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

function ProductData(props) {
    const navigate = useNavigate();

    const handleClick = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                
                navigate("/photoshoot");
            } else {
                
                navigate("/register");
            }
        });
    };

    return (
        <div className="p-card">
            <div className="product-image">
                <img src={props.image} alt="product"/>
            </div>
            <h4>{props.heading}</h4>
            <p>{props.text}</p>
            <nav>
                <button onClick={handleClick} className="photoshoot-link">
                    <FaHandPointer className="button-icon" /> Clicked Here
                </button>
            </nav>            
        </div>
    );
}

export default ProductData;
