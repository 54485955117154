import "./AboutUs.css";

function AboutUs (){
    return(
        <div className="about-container">
            <h1>VISI</h1>
            <p>Menjadi agency muda dan kreatif yang bergerak dibidang digital marketing dan media entertainment 
                dengan kualifikasi dan kompetensi internasional dan profesional</p>
            <h1>MISI</h1>
            <p>Membangun tim agency yang memiliki kompetensi yang berstandar internasional, profesional dan berintegritas,
                membangun kemitraan untuk menjadi mitra kunci yang terpercaya dalam membangun perusahaan konsumen,
                dan membangun tim agency yang dapat berkembang dalam perkembangan bidang industri.
            </p>
        </div>
    )
}

export default AboutUs;