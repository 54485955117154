import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layar from "../components/Layar";
import Navbar from "../components/Navbar";
import bg from "../asset/LOGO_DESAINERIAM.png";
import Division from "../components/Division";
import Product from "../components/Product";
import Footer from "../components/Footer";
import './Home.css';

function Home() {
    const [showPopup, setShowPopup] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480); 
    const [isAuthenticated, setIsAuthenticated] = useState(false); 
    const navigate = useNavigate();

    useEffect(() => {
        
        const user = localStorage.getItem('user'); 
        if (user) {
            setIsAuthenticated(true); 
        }

        const loadCorrectCSS = () => {
            const isMobile = window.innerWidth <= 480;
            setIsMobile(isMobile);
        };

        window.addEventListener("resize", loadCorrectCSS);
        return () => window.removeEventListener("resize", loadCorrectCSS);
    }, []);

    const handleNavigate = (path) => {
        setShowPopup(false);

        
        if (path === '/login' || path === '/register') {
            localStorage.setItem('user', 'authenticated'); 
            setIsAuthenticated(true); 
        }

        navigate(path);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleLogout = () => {
        
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        navigate('/'); 
    };

    return (
        <>
            <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} disabled={showPopup} />

            {/* Hamburger Menu for Mobile */}
            {showPopup && isMobile && (
                <div className="hamburger-menu" onClick={togglePopup}>
                    <div className="popup">
                        <div className="popupContent">
                            <h3>Do you have an account?</h3>
                            <div className="button-container">
                                <button onClick={() => handleNavigate('/login')}>I already have an account</button>
                                <button onClick={() => handleNavigate('/')}>Go to Home</button>
                                <button onClick={() => handleNavigate('/register')}>I don't have an account</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Popup for Desktop */}
            {showPopup && !isMobile && (
                <div className="popup">
                    <div className="popupContent">
                        <h3>Do you have an account?</h3>
                        <div className="button-container">
                            <button onClick={() => handleNavigate('/login')}>I already have an account</button>
                            <button onClick={() => handleNavigate('/')}>Go to Home</button>
                            <button onClick={() => handleNavigate('/register')}>I don't have an account</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Other Components */}
            <Layar
                cName="layar"
                layarIMG={bg}
                title="DIAM PORTFOLIO"
                text="Choose Your Favorite Destination"
                buttonText="DIAM PRODUCTION"
                url="/"
                btnClass="show"
                disabled={showPopup}
            />
            <Division disabled={showPopup} />
            <Product disabled={showPopup} />
            <Footer disabled={showPopup} />
        </>
    );
}

export default Home;


